import React, { useEffect } from 'react';
import { Button as ScrollLink } from 'react-scroll';

import Logo from './assets/logo.png?base64';

import styles from './part-header.module.scss';

export default function PartHeader() {
  const menu = [
    {
      title: 'Why replace',
      scrollToElementId: 'whyReplace',
    },
    {
      title: 'Our Advantage',
      scrollToElementId: 'ourAdvantage',
    },
    {
      title: 'Technologies',
      scrollToElementId: 'technologies',
    },
  ];

  useEffect(() => {
    const handleUtmParameters = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const utmSource = urlParams.get('utm_source');
      const utmMedium = urlParams.get('utm_medium');
      const utmCampaign = urlParams.get('utm_campaign');
      const utmTerm = urlParams.get('utm_term');
      const utmContent = urlParams.get('utm_content');
      const fbclid = urlParams.get('fbclid');

      if (utmSource || utmMedium || utmCampaign || utmTerm || utmContent || fbclid) {
        localStorage.setItem('utm_source', utmSource || '');
        localStorage.setItem('utm_medium', utmMedium || '');
        localStorage.setItem('utm_campaign', utmCampaign || '');
        localStorage.setItem('utm_term', utmTerm || '');
        localStorage.setItem('utm_content', utmContent || '');
        localStorage.setItem('fbclid', fbclid || '');
      }
    };

    handleUtmParameters();
  }, []);

  return (
    <div className={styles.header}>
      <div className={styles.leftContainer}>
        <img
          className={styles.logo}
          src={Logo}
          alt="company logo"
        />
      </div>
      <div className={styles.menuContainer}>
        {menu.map(({ title, scrollToElementId }) => (
          <ScrollLink
            key={title}
            className={styles.menuItem}
            to={scrollToElementId}
            smooth
            duration={500}
          >
            {title}
          </ScrollLink>
        ))}
      </div>
      <div className={styles.rightSpacer} />
    </div>
  );
}
