import { useField } from 'formik';
import React from 'react';

import styles from './part-form-input.module.scss';

export default function PartFormInput({ title = '', ...props }) {
  const [field, meta] = useField(props);
  return (
    <div className={styles.PartFormInput}>
      <p className={styles.PartFormInput__title}>{title}</p>
      <label
        className={styles.PartFormInput__label}
        data-invalid={meta.touched && meta.error ? '' : undefined}
      >
        <input className={styles.PartFormInput__input} {...field} {...props} />
        <span className={styles.PartFormInput__errorTip}>{meta.error}</span>
      </label>
    </div>
  );
}

